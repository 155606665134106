import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import UplSpinner from '../../atoms/UplSpinner/UplSpinner';
import { PAYMENT_TYPE } from '../../../constants/MyPageConstants';

const UN_AUTH_ROUTES = [
  /\/login*/,
  /\/signup*/,
  /\/password*/,
  /\/maintenance*/,
];
export const unAuthRoute = (path: string) =>
  UN_AUTH_ROUTES.some((route) => route.test(path));

const REQUIRE_LOGOUT_ROUTES = [/\/login*/];
const requireLogoutRoute = (path: string) =>
  REQUIRE_LOGOUT_ROUTES.some((route) => route.test(path));

const ESTIMATE_ROUTES = [/\/estimate*/, /\/register\/(?!wait|done).+/];
const requireEstimateRoute = (path: string) =>
  ESTIMATE_ROUTES.some((route) => route.test(path));

const UplAuthWrapper = ({ component }: { component: JSX.Element }) => {
  const { data, status } = useSession();
  const router = useRouter();
  // 認証済みでないかつログインが必要なページであるかどうか
  const unAuth = useMemo(
    () => status !== 'authenticated' && !unAuthRoute(router.pathname),
    [status, router.pathname],
  );
  // 認証済みかつログアウトが必要なページかどうか
  const auth = useMemo(
    () => status === 'authenticated' && requireLogoutRoute(router.pathname),
    [status, router.pathname],
  );

  // クレジット会員は見積ページ遷移不可とする
  const credit = useMemo(
    () =>
      data?.user.customer.payType === PAYMENT_TYPE.credit &&
      requireEstimateRoute(router.pathname),
    [data?.user.customer.payType, router.pathname],
  );

  useEffect(() => {
    if (status !== 'loading' && unAuth) {
      signOut({ callbackUrl: '/login' });
      localStorage.removeItem('CHECKOUT_ID');
    } else if (
      (status !== 'loading' && auth) ||
      (status !== 'loading' && credit)
    ) {
      router.push('/');
    }
  }, [auth, unAuth, router, status, credit]);

  if (unAuth || auth || credit || status === 'loading') {
    return (
      <div>
        <UplSpinner />
      </div>
    );
  }

  return component;
};

export default UplAuthWrapper;
